export const appConstants = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  LOGGED_IN: 'loggedIn',
  USER_ID: 'user_id',
  EMAIL: 'email',
  LANGUAGE: 'language',
  FORGOT_PWD_EMAIL: 'forgot_pwd_email',
  PARTNER_ID: 'PartnerId',
  USERPOOLID: 'userPoolId',
  CRONJOB_TRIGGER_TIME: 9,
};
