import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ProgressLoaderService {
  constructor(public spinner: NgxSpinnerService) { }

  /**
   * Show the progress loader.
   */
  show(): void {
    setTimeout(() => {
      this.spinner.show();
    });
  }

  /**
   * Hide the progress loader
   */
  hide(): void {
    this.spinner.hide();
  }
}
